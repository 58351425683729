<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-4 px-2 px-lg-0 pl-lg-4 pl-xl-4>
      <v-flex xs12>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Shipped Orders
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-center
      py-4
      px-2
      pl-xl-4
      v-for="(item, i) in orders"
      :key="i"
    >
      <v-flex xs12 sm11 text-left pb-4 pl-2>
        <span
          style="font-family: opensansbold; font-size: 20px; color: #808080"
        >
          {{ formatDate(item.create_date) }}
        </span>
      </v-flex>
      <v-layout wrap justify-center>
        <v-flex xs12 sm11>
          <v-card>
            <v-layout wrap justify-star py-3 px-5>
              <v-flex xs12 py-2>
                <v-layout wrap justify-space-between>
                  <v-flex text-left>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Order Date
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span style="color: #000000; font-family: poppinsmedium"
                          >{{ formatDate(item.create_date) }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Order Number
                        </span>
                      </v-flex>
                      <v-flex xs12 v-if="item.orderId">
                        <span style="color: #000000; font-family: poppinsmedium"
                          >{{ item.orderId }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-right>
                    <v-layout wrap justify-end>
                      <v-flex xs12>
                        <span
                          style="color: #7e8f85; font-family: poppinsregular"
                          >Status
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="color: #30b868; font-family: poppinsmedium"
                          >{{ item.orderStatus }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-4>
                <v-divider></v-divider>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
                lg12
                xl12
                py-2
                text-start
                v-for="(transaction, i) in item.items"
                :key="i"
              >
                <ShippingOrdersItem v-bind:storage="transaction" />
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 sm6 text-left>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-btn
                      color="#30B868"
                      dark
                      :to="'/orderDetails?id=' + item._id"
                      ><span
                        style="font-family: opensanssemibold; font-size: 13px"
                        >VIEW ORDER</span
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 text-right>
                <v-layout wrap justify-end>
                  <v-flex xs12 pb-3 v-if="item.discountedPrice > 0">
                    <span
                      style="
                        color: #3d3b3b;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Discount Applied :</span
                    >
                    <span style="color: #3d3b3b; font-family: poppinsregular"
                      >₹ {{ item.discountedPrice }}</span
                    >
                  </v-flex>
                  <v-flex xs12 pb-4>
                    <span
                      style="
                        color: #3d3b3b;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                      >Total Amount :
                    </span>
                    <span style="color: #383838; font-family: poppinsmedium"
                      >₹ {{ item.subTotal }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout wrap justify-center v-if="orders.length < 1">
      <v-flex xs12>
        <span
          style="font-family: poppinsregular; font-size: 25px; color: #000000"
        >
          Oops! No Products Found
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import ShippingOrdersItem from "./shippingOrdersItem";
export default {
  components: {
    ShippingOrdersItem,
  },
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      orders: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchaseList/seller",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          orderStatus: "Shipped",
        },
        // data: {
        //   deliverystatus: ["Placed", "Shipped", "In Transit"],
        // },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.orders = response.data.data;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var defDate = new Date(item);
      defDate = defDate.toString().slice(0, 15);
      var today = new Date();
      today = today.toString().slice(0, 15);

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = yesterday.toString().slice(0, 15);

      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(0, 4) + day + " " + dt.slice(4, 7) + " " + year;

      if (today == defDate) {
        return "Today";
      } else if (yesterday == defDate) {
        return "Yesterday";
      } else {
        return strTime;
      }
    },
  },
};
</script>