<template>
    <div>
      <v-layout wrap>
        <v-flex xs12>
          <v-layout wrap>
            <v-flex xs2 v-if="storage.productId">
              <v-img
                :src="mediaUURL + storage.productId.photos[0]"
                height="100%"
                width="auto"
                contain
                :alt="storage.name"
              >
                <template v-slot:placeholder>
                  <ImageLoader />
                </template>
              </v-img>
            </v-flex>
            <v-flex xs9>
              <v-layout wrap justify-center fill-height>
                <v-flex xs12 sm12 md12 align-self-center pl-2 text-left>
                  <v-layout
                    wrap
                    justify-start
                    style="line-height: 16px"
                    fill-height
                    v-if="storage.productId"
                  >
                    <v-flex xs12>
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 15px;
                          color: #191919;
                        "
                      >
                        {{ storage.productId.productname }}
                        <!-- <span v-if="list.name.length > 25"> ... </span> -->
                      </span>
                    </v-flex>
                    <v-flex xs12 pt-3 v-if="storage.itemId">
                      <span
                        style="
                          color: #191919;
                          font-size: 13px;
                          font-family: poppinsmedium;
                        "
                        >Size
                        <span style="font-family: poppinsregular"
                          >: {{ storage.itemId.size }}</span
                        >
                      </span>
                    </v-flex>
                    <v-flex xs12 pt-1>
                      <span
                        style="
                          color: #191919;
                          font-size: 13px;
                          font-family: poppinsmedium;
                        "
                        >OTY
                        <span style="font-family: poppinsregular"
                          >: {{ storage.quantity }}
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  export default {
    props: ["storage"],
    data() {
      return {};
    },
  };
  </script>